import React from 'react';

export const SharePanel = props => {

    if (props.type != 'news') {
        const id = (props.media.strapiId) ? props.media.strapiId : props.media.id;
        const lang = props.lang;

        return (
            <div class="share-panel">
                <button id="fb-share" onClick={() => eval(`window.open('https://facebook.com/sharer.php?u=' + encodeURI('https://admin.emiratesmarsmission.ae/share-link/${lang}/${props.type}/${id}/emm'), 'fb-share', 'width=500,height=600')`)}></button>
                <button id="twitter-share" onClick={() => {
                    const cmd = `window.open('https://twitter.com/intent/tweet?url=' + encodeURI('https://admin.emiratesmarsmission.ae/share-link/${lang}/${props.type}/${id}/emm'), 'twitter-share', 'width=500,height=600')`;
                    eval(cmd);
                }}></button>
            </div>
        )
    } else {
        return (
            <div class="share-panel">
                <button id="fb-share" onClick={() => eval(`window.open('https://facebook.com/sharer.php?u=' + encodeURI('${props.newsUrl}'), 'fb-share', 'width=500,height=600')`)}></button>
                <button id="twitter-share" onClick={() => {
                    const cmd = `window.open('https://twitter.com/intent/tweet?url=' + encodeURI('${props.newsUrl}'), 'twitter-share', 'width=500,height=600')`;
                    eval(cmd);
                }}></button>
            </div>
        )
    }

}