import React from "react"
import { navigate } from "gatsby"

export default function Paginator(props) {
  function changePageNumberTo(num) {
    if (num > 0 && num <= props.totalPages) {
      navigate(`${props.prefix}${num}`)
    }
  }

  // console.log(props);

  return (
    <div className="page-navigator">
      <button onClick={() => changePageNumberTo(props.pageNumber - 1)}>
        <span data-uk-icon="icon: chevron-left"></span>
      </button>
      <div
        style={{
          marginLeft: "10px",
          marginRight: "10px",
          display: "inline-block",
        }}
      >
        <input
          type="number"
          onChange={e => changePageNumberTo(e.target.value)}
          value={props.pageNumber}
        />
        &nbsp;&nbsp;/&nbsp;&nbsp;
        {props.totalPages}
      </div>
      <button onClick={() => changePageNumberTo(props.pageNumber + 1)}>
        <span data-uk-icon="icon: chevron-right"></span>
      </button>
    </div>
  )
}
