import React, { useEffect, useState } from "react";
import { SharePanel } from './share-panel';

const NewsCard = ({ news, lang }) => {

    let {title, image, description, url} = news;
    if (Array.isArray(image)) {
        if (image[0] !== undefined) {
            image = 'https://www.admin-emarsmission.aspgulf.net' + image[0].url;
        } else {
            image = "";
        }
    }

    return (
    
        <a href={url} className="no-style-change-link" target="_blank">
            <div className="flex flex-column flex-row-ns mb5">
                
                <div className="w-100 w-30-ns">
                    <img src={image} alt="Image" />
                </div>
                <div className="w-100 w-60-ns mh4-ns">
                    <h3>{title}</h3>
                    <SharePanel type={'news'} newsUrl={url} />
                    <p>{description}</p>
                </div>
            </div>
        </a>
    )
}

export default NewsCard;