import React from "react";

import Layout from "./layout"
import SEO from "./seo"
import NewsCard from "./NewsCard";
import Paginator from '../components/paginator';

const NewsPage = ({pageContext}) => {

  let { newsItems, totalPages, lang, pageNumber } = pageContext;
  const tempNewsItems = newsItems.map((e, index) => (e.created_at + "::" + index));
  tempNewsItems.sort();
  tempNewsItems.reverse();
  newsItems = tempNewsItems.map(e => (newsItems[Number(e.split("::")[1])]));

  return (
    <Layout lang={lang}>
      <SEO title="News" lang={lang} />
      <main>
      <div className="standard-container">
        <h1 className="mb5 mt6">{(lang=='ar')? 'آخر الأخبار': 'News feed'}</h1>
        <div className="ph3 ph0-ns mb6">
          {newsItems.map(newsItem => (
            <NewsCard news={newsItem} lang={lang} />
          ))}
        </div>
        <Paginator totalPages={totalPages} pageNumber={pageNumber} prefix={(lang == 'ar') ? `/ar/news` : `/news`} />
      </div>
      </main>
    </Layout>
  )
}

export default NewsPage;
